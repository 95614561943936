import { render, staticRenderFns } from "./TravelPolicyCa.vue?vue&type=template&id=1b372cfa&scoped=true"
import script from "./TravelPolicyCa.vue?vue&type=script&lang=js"
export * from "./TravelPolicyCa.vue?vue&type=script&lang=js"
import style0 from "./TravelPolicyCa.vue?vue&type=style&index=0&id=1b372cfa&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b372cfa",
  null
  
)

export default component.exports