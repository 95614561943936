<template>
  <v-container
    class="light-blue lighten-5 pb-16 px-1"
    fluid
  >
    <v-row class="d-flex justify-center pb-16">
      <v-col
        cols="12"
        sm="11"
        md="9"
      >
        <v-card class="white px-auto">
          <v-stepper>
            <v-stepper-header>
              <v-stepper-step
                step="1"
                complete
                complete-icon="mdi-airplane-check"
              >
                TRIP INFO
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                step="2"
                complete
                complete-icon="mdi-airplane-check"
              >
                CHOOSE PLAN
              </v-stepper-step>

              <v-divider></v-divider>

              <v-stepper-step
                complete
                complete-icon="mdi-airplane-check"
                step="3"
              >
                PURCHASE PLAN
              </v-stepper-step>
              <v-divider></v-divider>
              <v-stepper-step
                complete
                complete-icon="mdi-airplane-check"
                step="4"
              >
                DOWNLOAD YOUR POLICY
              </v-stepper-step>
            </v-stepper-header>
          </v-stepper>
        </v-card>
        <v-card
          v-if="displayError"
          min-height="600"
        >
          <v-card-title class="justify-center text-h5 primmary--text">
            There was an error with this request
          </v-card-title>
          <v-row class="justify-center">
            <v-col
              cols="10"
              class="text-h5 primmary--text mt-4 pa-4"
            >
              {{ errorMessage }}
            </v-col>
          </v-row>

          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  color="primary"
                  class="mb-2 mt-4 font-weight-bold"
                  to="/"
                  @click="submit(); clickedFieldGlobal(
                    'travelPolicy homeBtn',
                    124,
                    'Canadian Travel Survey v1'
                  )"
                >
                  Home
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
        <v-card
          elevation="1"
        >
          <v-row class="justify-center">
            <v-col
              cols="12"
              sm="11"
              md="12"
            >
              <v-row>
                <v-col class="d-inline-flex align-center mt-16 justify-center">
                  <v-img
                    src="/img/celebration 1.svg"
                    :height="mobileDevice ? '40px' : '64px'"
                    :max-width="mobileDevice ? '40px' : '64px'"
                    class="mr-4"
                  ></v-img>
                  <div :class="mobileDevice ? 'title-text-mobile' : 'title-text'">
                    Congratulations!
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <p class="title-subtext">
                    Thank you for your purchase.
                  </p>
                </v-col>
              </v-row>
              <v-row
                no-gutters
                class="d-flex justify-center"
              >
                <v-col
                  cols="12"
                  sm="11"
                  md="11"
                  lg="10"
                >
                  <v-card
                    elevation="0"
                  >
                    <div v-show="!mobileDevice">
                      <v-row
                        class="grey-background grey-outline table-fields"
                      >
                        <v-col cols="3">
                          Provider
                        </v-col>
                        <v-col cols="3">
                          Effective Date
                        </v-col>
                        <v-col cols="3">
                          Amount
                        </v-col>
                        <v-col cols="3">
                          Policy No.
                        </v-col>
                      </v-row>
                      <v-row
                        class="grey-outline table-fields"
                      >
                        <v-col cols="3">
                          Tugo
                        </v-col>
                        <!-- <v-col cols="3">
                          {{ policy.product[0] }}
                        </v-col> -->
                        <v-col cols="3">
                          {{ userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.effectiveDate }}
                        </v-col>
                        <v-col cols="3">
                          <v-chip
                            class="primary"
                          >
                            <span class="table-fields white--text">{{ formatAmount(userpolicy.policyTugo.totalPremiums) }} {{ userpolicy.policyTugo.policyInfo.ratedPrice.currencyCode }}</span>
                          </v-chip>
                        </v-col>
                        <v-col cols="3">
                          {{ userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.policyNumber && userpolicy.policyTugo.policyInfo.policyNumber.referenceNumber }}
                        </v-col>
                        <!-- <v-col cols="12">
                          <v-btn
                            elevation="0"
                            icon
                            large
                            dark
                            color="primary"
                          >
                            <v-icon dark>
                              mdi-email
                            </v-icon>
                          </v-btn>
                          <v-btn
                            elevation="0"
                            icon
                            large
                            dark
                            color="primary"
                          >
                            <v-icon dark>
                              mdi-download
                            </v-icon>
                          </v-btn>
                        </v-col> -->
                      </v-row>
                    </div>
                    <div v-if="mobileDevice">
                      <v-row
                        class="table-fields d-flex justify-center"
                      >
                        <v-col
                          cols="11"
                          class="grey-background grey-outline"
                        >
                          <v-col cols="12">
                            Provider: Tugo
                          </v-col>
                          <v-col cols="12">
                            Effective Date : {{ userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.effectiveDate }}
                          </v-col>
                          <v-col
                            cols="12"
                          >
                            Amount :  <v-chip
                              class="primary"
                            >
                              <span class="table-fields white--text">{{ formatAmount(userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.ratedPrice.total) }} {{ userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.ratedPrice.currencyCode }}</span>
                            </v-chip>
                          </v-col>
                          <v-col cols="12">
                            Policy No. : {{ userpolicy.policyTugo.policyInfo && userpolicy.policyTugo.policyInfo.policyNumber.referenceNumber }}
                          </v-col>
                          <!-- <v-col cols="12">
                            Actions : <v-btn
                              elevation="0"
                              icon
                              large
                              dark
                              color="primary"
                            >
                              <v-icon dark>
                                mdi-email
                              </v-icon>
                            </v-btn>
                            <v-btn
                              elevation="0"
                              icon
                              large
                              dark
                              color="primary"
                            >
                              <v-icon dark>
                                mdi-download
                              </v-icon>
                            </v-btn>
                          </v-col> -->
                        </v-col>
                      </v-row>
                    </div>
                  </v-card>
                </v-col>
              </v-row>
              <v-row class="d-flex justify-center">
                <v-col
                  cols="11"
                  class="d-flex justify-center"
                >
                  <div class="policy-txt">
                    This policy has been added to Your Blanket Account. You can view all of your policies under <a
                      class="policy-txt primary--text"
                      @click="toUserPolcies(); clickedFieldGlobal('travelPolicy viewUserPolicies', 125, 'American Travel Survey v1')"
                    >
                      My Policies.
                    </a>
                  </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col
                  :class="mobileDevice ? 'justify-center' : 'justify-end pa-6'"
                  class="d-flex"
                >
                  <v-btn
                    large
                    right
                    class="primary font-weight-bold mt-4 mb-2"
                    to="/userpolicies"
                    @click="clickedFieldGlobal('travelPolicy viewUserPolicies', 126, 'American Travel Survey v1')"
                  >
                    View My Policies
                  </v-btn>
                </v-col>
              </v-row>
              <!-- <v-row
                no-gutters
                class="justify-center"
              >
                <v-col
                  cols="11"
                  sm="11"
                  md="11"
                  lg="10"
                  class="headline"
                >
                  <div class="mt-10">
                    ADDITIONAL DETAILS
                  </div>
                  <v-card class="py-3 px-1 pa-md-4">
                    <v-card-title>
                      Policy Status: {{ userpolicy.policyTugo.policyInfo.policyStatus }}
                    </v-card-title>
                    <v-card-title>
                      Tugo Reference: {{ userpolicy.policyTugo.uid }} <br>Policy Number: {{ userpolicy.policyTugo.policyInfo.policyNumber.referenceNumber }}
                    </v-card-title>
                    <v-card-text>
                      <v-card-text>
                        Product Code: {{ userpolicy.policyTugo.policyInfo.productLineCode }}
                      </v-card-text>
                      <v-card-text>
                        Product Type {{ userpolicy.policyTugo.policyInfo.productType }} Trip Type: {{ userpolicy.policyTugo.policyInfo.tripType }}
                      </v-card-text>
                      <v-card-text>
                        Group: {{ userpolicy.policyTugo.policyInfo.insuredGroupType }}
                      </v-card-text>
                      <v-card-text>
                        Trip Start Date: {{ userpolicy.policyTugo.trip.startDate }} <br>
                        Trip End Date: {{ userpolicy.policyTugo.trip.endDate }}
                      </v-card-text>
                      <v-card-text>
                        Applied Date: {{ userpolicy.policyTugo.policyInfo.applicationDate }} <br>
                        Policy Created Date: {{ userpolicy.policyTugo.policyInfo.createdDate }} <br>
                        Effective Date: {{ userpolicy.policyTugo.policyInfo.effectiveDate }}
                      </v-card-text>
                      <v-list>
                        <v-list-item>
                          Partner Name: {{ userpolicy.policyTugo.partnerName }}
                          {{ userpolicy.policyTugo.policyInfo.partnerName }}
                        </v-list-item>
                        <v-list-item>
                          <v-list-item>
                            Status: {{ userpolicy.policyTugo.policyInfo.paymentStatus }}
                          </v-list-item>
                          <v-list-item>
                            Card Type: {{ userpolicy.policyTugo.policyInfo.ratedPrice.type }}
                          </v-list-item>
                          <v-list-item>
                            Amount: {{ formatAmount(userpolicy.policyTugo.policyInfo.ratedPrice.total) }} {{ userpolicy.policyTugo.policyInfo.ratedPrice.currencyCode }}
                          </v-list-item>
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-text>
                      <v-list>
                        <v-list-item class="headline primary--text">
                          Traveler Info
                        </v-list-item>
                      </v-list>
                    </v-card-text>
                    <v-card-text
                      v-for="(person, index) of userpolicy.policyTugo.insuredPersons"
                      :key="index"
                    >
                      <v-list>
                        <v-list-item class="headline primary--text">
                          Traveler: {{ person.firstName }} {{ person.lastName }} <br>
                          Date of Birth: {{ person.birthDate }}
                        </v-list-item>
                        <v-list
                          v-for="(plan, idx) of person.selectedPlans"
                          :key="idx"
                        >
                          <v-list-item>
                            Plan Name: {{ plan.genericName }} Cost: {{ formatAmount(plan.chargedPrice.total) }} {{ plan.chargedPrice.currencyCode }}
                          </v-list-item>
                          <v-list-item>
                            Plan Code: {{ plan.planCode }}
                          </v-list-item>

                          <v-list-item>
                            Expires: {{ plan.expiryDate }}
                          </v-list-item>
                          <v-list-item>
                            <v-list>
                              <v-list-item
                                v-for="(entry, i) of plan.coverageLimits"
                                :key="i"
                              >
                                Code: {{ entry.priceItemCode }} Category: {{ entry.category }} Limit: {{ entry.limit }}
                              </v-list-item>
                            </v-list>
                          </v-list-item>
                        </v-list>
                      </v-list>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row> -->
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <div class="d-flex justify-center">
                <v-btn
                  color="primary"
                  class="mb-2 font-weight-bold"
                  to="/"
                  @click="submit(); clickedFieldGlobal(
                    'travelPolicy homeBtn',
                    127,
                    'Canadian Travel Survey v1'
                  )"
                >
                  Home
                </v-btn>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  var numeral = require('numeral')

  // FOR TESTING ONLY,

  // var testPolicyData = {
  //   haserror: false,
  //   policyTugo: {
  //     uid: 'FGS3781193-0-0',
  //     policyInfo: {
  //       policyNumber: {
  //         referenceNumber: 'FGS3781193',
  //         historyNumber: 0,
  //       },
  //       sourceSystemCode: 'WEBSERVICE',
  //       productLineCode: 'PR-FDM-1',
  //       productBundleCode: 'BUN-FDM-GEN',
  //       productType: 'TRAVEL',
  //       tripType: 'SINGLE',
  //       insuredGroupType: 'INDIVIDUAL',
  //       applicationDate: '2022-12-15',
  //       effectiveDate: '2022-12-16',
  //       expiryDate: '2022-12-23',
  //       partnerCode: 'NAD100',
  //       partnerName: '10169536 CANADA INC. doing business as Nadavot',
  //       partnerGroupCode: 'NADAVOT',
  //       partnerGroupName: '10169536 CANADA INC. doing business as Nadavot',
  //       policyStatus: 'APPROVED',
  //       paymentStatus: 'PAID',
  //       emailFulfillment: false,
  //       excludeMHQ: false,
  //       letterFulfillment: false,
  //       minimumApplied: false,
  //       autoRenewal: false,
  //       amended: false,
  //       amendmentVersion: 0,
  //       renewed: false,
  //       claimed: false,
  //       ratedPrice: {
  //         type: 'DEBIT',
  //         currencyCode: 'CAD',
  //         total: 111,
  //         comm: 0,
  //         commPct: 0,
  //         net: 111,
  //       },
  //       chargedPrice: {
  //         type: 'DEBIT',
  //         currencyCode: 'CAD',
  //         total: 111,
  //         comm: 0,
  //         commPct: 0,
  //         net: 111,
  //       },
  //       createdDate: '2022-12-15',
  //       createdBy: 'NAD100',
  //       lastUpdatedDate: '2022-12-15',
  //       lastUpdatedBy: 'NAD100',
  //       primaryContact: {
  //         firstName: 'Jack',
  //         lastName: 'Beanstalk',
  //         id: 'WS-PERSON-1671130338627-204195',
  //         birthDate: '2010-02-02',
  //         age: 12,
  //         language: 'en',
  //         phoneNumbers: [
  //           {
  //             id: 'TU-PERSONPHONE-5273413',
  //             number: '6041232413',
  //             areaCode: '604',
  //             primary: true,
  //           },
  //         ],
  //         addresses: [
  //           {
  //             id: 'TU-PERSONADDRESS-7537003',
  //             addressLine1: 'P.O. Box 106, 1393 Eget Ave',
  //             city: 'VANCOUVER',
  //             country: 'CA',
  //             province: 'AB',
  //             postalCode: 'T7Y 8J0',
  //             primary: true,
  //             type: 'MAIL',
  //           },
  //         ],
  //         emails: [],
  //       },
  //     },
  //     trip: {
  //       startDate: '2022-12-16',
  //       endDate: '2022-12-23',
  //     },
  //     insuredPersons: [
  //       {
  //         id: 'WS-PERSON-1671130338627-204195',
  //         insuredType: 'CANADIAN',
  //         insuredGroupType: 'INDIVIDUAL',
  //         inHomeProvince: true,
  //         homeProvince: 'AB',
  //         taxes: 0,
  //         firstName: 'Jack',
  //         lastName: 'Beanstalk',
  //         birthDate: '2010-02-02',
  //         age: 12,
  //         language: 'en',
  //         addresses: [
  //           {
  //             id: 'TU-PERSONADDRESS-7537003',
  //             addressLine1: 'P.O. Box 106, 1393 Eget Ave',
  //             city: 'VANCOUVER',
  //             country: 'CA',
  //             province: 'AB',
  //             postalCode: 'T7Y 8J0',
  //             primary: true,
  //             type: 'MAIL',
  //           },
  //         ],
  //         emails: [],
  //         selectedPlans: [
  //           {
  //             id: 'TU-SINGLETRIPINSUREDPLAN-6931403',
  //             planCode: 'PL-SPCK-2',
  //             genericName: 'All Inclusive Package',
  //             productCode: 'PR-FDM-1',
  //             effectiveDate: '2022-12-16',
  //             expiryDate: '2022-12-23',
  //             isExtension: false,
  //             minimumApplied: false,
  //             hasPriceOverridden: false,
  //             ratedPrice: {
  //               type: 'DEBIT',
  //               currencyCode: 'CAD',
  //               total: 111,
  //               comm: 0,
  //               commPct: 0,
  //               net: 111,
  //             },
  //             chargedPrice: {
  //               type: 'DEBIT',
  //               currencyCode: 'CAD',
  //               total: 111,
  //               comm: 0,
  //               commPct: 0,
  //               net: 111,
  //             },
  //             priceInputParameters: [
  //               {
  //                 code: 'SUMP',
  //                 value: 1000,
  //                 grossPremium: null,
  //                 overriddenPrice: null,
  //               },
  //               {
  //                 code: 'PM-CFAR-1',
  //                 value: null,
  //                 grossPremium: null,
  //                 overriddenPrice: null,
  //               },
  //             ],
  //             priceBreakdown: {
  //               planCode: 'PL-SPCK-2',
  //               planName: 'Traveller - All Inclusive - Single Trip (SWP)',
  //               planType: 'SINGLE',
  //               basePlanInfo: {},
  //               personId: 'WS-PERSON-1671130338627-204195',
  //               premium: 111,
  //               minimumPremium: 0,
  //               tax: 0,
  //               planCost: 111,
  //               baseRatePriceItem: {
  //                 code: 'TAISTV-TAISTV60',
  //                 name: 'Traveller - All Inclusive - Single Trip, v12 - 2021-11',
  //                 inputClass: 'RATE',
  //                 amount: 111,
  //               },
  //               priceItems: [
  //                 {
  //                   code: 'SUMP',
  //                   name: 'Sum Insured Before Dept - 0 to 10000 w/1400',
  //                   type: 'SUMP',
  //                   inputClass: 'FACTOR',
  //                   value: 1000,
  //                 },
  //                 {
  //                   code: 'TAISTV-TAISTV60',
  //                   name: 'Traveller - All Inclusive - Single Trip, v12 - 2021-11',
  //                   inputClass: 'RATE',
  //                   amount: 111,
  //                 },
  //                 {
  //                   code: 'TRIP',
  //                   name: 'Trip Length Range [1, 5, 10, 17, 24, 31, 38, 46, 53-60]',
  //                   type: 'TRIP',
  //                   inputClass: 'FACTOR',
  //                   value: 8,
  //                 },
  //               ],
  //               available: true,
  //             },
  //             coverageLimits: [
  //               {
  //                 category: 'MED',
  //                 basis: 'INSDTRIP',
  //                 limit: 5000000,
  //                 priceItemCode: 'TU-COVERAGE-2000363',
  //                 sourceType: 'BASE',
  //               },
  //               {
  //                 category: 'BAG',
  //                 basis: 'INSDTRIP',
  //                 limit: 500,
  //                 priceItemCode: 'TU-COVERAGE-2000463',
  //                 sourceType: 'BASE',
  //               },
  //               {
  //                 category: 'TRIPINT',
  //                 basis: 'INSDTRIP',
  //                 limit: 25000,
  //                 priceItemCode: 'TU-COVERAGE-2000403',
  //                 sourceType: 'BASE',
  //               },
  //               {
  //                 category: 'AIR',
  //                 basis: 'INSDTRIP',
  //                 limit: 100000,
  //                 priceItemCode: 'TU-COVERAGE-2000443',
  //                 sourceType: 'BASE',
  //               },
  //               {
  //                 category: 'TRIPCAN',
  //                 basis: 'INSDTRIP',
  //                 limit: 1000,
  //                 priceItemCode: 'TU-COVERAGE-2000383',
  //                 sourceType: 'BASE',
  //               },
  //               {
  //                 category: 'ACC',
  //                 basis: 'INSDTRIP',
  //                 limit: 25000,
  //                 priceItemCode: 'TU-COVERAGE-2000423',
  //                 sourceType: 'BASE',
  //               },
  //             ],
  //           },
  //         ],
  //         phoneNumbers: [
  //           {
  //             id: 'TU-PERSONPHONE-5273413',
  //             number: '6041232413',
  //             areaCode: '604',
  //             primary: true,
  //           },
  //         ],
  //         isStudent: false,
  //       },
  //     ],
  //     contacts: [
  //       {
  //         firstName: 'Jack',
  //         lastName: 'Beanstalk',
  //         id: 'WS-PERSON-1671130338627-204195',
  //         birthDate: '2010-02-02',
  //         age: 12,
  //         language: 'en',
  //         phoneNumbers: [
  //           {
  //             id: 'TU-PERSONPHONE-5273413',
  //             number: '6041232413',
  //             areaCode: '604',
  //             primary: true,
  //           },
  //         ],
  //         addresses: [
  //           {
  //             id: 'TU-PERSONADDRESS-7537003',
  //             addressLine1: 'P.O. Box 106, 1393 Eget Ave',
  //             city: 'VANCOUVER',
  //             country: 'CA',
  //             province: 'AB',
  //             postalCode: 'T7Y 8J0',
  //             primary: true,
  //             type: 'MAIL',
  //           },
  //         ],
  //         emails: [],
  //       },
  //     ],
  //     policyLogNotes: [],
  //     payments: [
  //       {
  //         id: 'TU-POLICYPAYMENT-4277103',
  //         payeeId: 'WS-PERSON-1671130338627-204195',
  //         orderNumber: 'FGS3781193-0-338863',
  //         transactionType: 'PURCHASE',
  //         transactionDate: '2022-12-15T10:52:19-0800',
  //         paymentStatus: 'APPROVED',
  //         paymentType: 'CREDITCARD',
  //         paymentMethod: 'SINGLE',
  //         currencyCode: 'CAD',
  //         amount: 111,
  //         creditCardType: 'VISA',
  //         maskedCardNumber: '403000******1234',
  //         cardHolder: 'Roger Wilco',
  //         cardExpirationDate: '2023-10',
  //         transactionId: '10227191',
  //         cardMessage: '1-Approved',
  //         authNumber: 'TEST',
  //         fees: [],
  //         createdVersion: '0',
  //       },
  //     ],
  //     policyStatements: [],
  //     policyAttributes: [],
  //     remarks: [],
  //     totalAdminFees: 0,
  //     totalPremiums: 111,
  //     totalTaxes: 0,
  //     totalPrice: 111,
  //   },
  // }

  export default {
    data () {
      return {
        checkbox: true,
        phone: '',
        travelProducts: '',
        travelDetails: '',
        termsprovince: '',
        termsagreed: '',
        cardexpyear: '',
        cardexpmonth: '',
        cardholder: '',
        cardnumber: '',
        cardsecuritycode: '',
        mailingFirstName: '',
        mailingLastName: '',
        mailingEmail: '',
        mailingAddress: '',
        mailingSuite: '',
        mailingCity: '',
        mailingState: '',
        mailingZip: '',
        billingFirstName: '',
        billingLastName: '',
        billingEmail: '',
        billingAddress: '',
        billingSuite: '',
        billingCity: '',
        billingState: '',
        billingZip: ''
      }
    },
    computed: {
      policyloaded () {
        if (this.$store.getters.getCanadianTravelPolicy) {
          console.log('policy loaded', this.$store.getters.getCanadianTravelPolicy)
          return true
        }
        return false
      },
      // policyloaded () {
      //   return true
      // },
      displayError () {
        console.log('error condition is ', this.$store.getters.getErrors)
        if (this.$store.getters.getErrors) {
          return true
        }
        return false
      },
      errorMessage () {
        let messages = ['Unknown Error']
        messages = this.$store.getters.getErrors
        return messages
      },
      userpolicy () {
        let policy = 'unknown'
        policy = this.$store.getters.getCanadianTravelPolicy
        console.log('User Policy is ', policy)
        return policy
      },
      selectedCost () {
        return numeral(0).format('$0.00')
      },
      mailingInfo () {
        return {
          firstName: this.mailingFirstName,
          lastName: this.mailingLastName,
          email: this.mailingEmail,
          suite: this.mailingSuite,
          city: this.mailingCity,
          state: this.mailingState,
          zip: this.mailingZip,
        }
      },
      billingInfo () {
        return {
          firstName: this.billingFirstName,
          lastName: this.billingLastName,
          email: this.billingEmail,
          suite: this.billingSuite,
          city: this.billingCity,
          state: this.billingState,
          zip: this.billingZip,
        }
      },
      mobileDevice () {
        return this.$vuetify.breakpoint.xsOnly
      },
      tabletDevice () {
        return this.$vuetify.breakpoint.smOnly
      },
    },
    created () {
      this.travelOptions = this.$store.getters.getTravelCoverageDetails
      console.log('traveloptions are ', this.travelOptions)
      this.travelDetails = this.$store.getters.getTravelDetails
      console.log(this.travelDetails)
      console.log('our details are ', this.travelDetails)
    },
    beforeDestroy () {
      this.$store.dispatch('clearError')
      this.$store.dispatch('resetTravelCanada')
    },
    methods: {
      formatAmount (val) {
        return numeral(val).format('$0.00')
      },
      async submit () {
        this.$router.push({ path: '/' })
      }
    }
  }
</script>

<style lang="scss" scoped>

.table-fields{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 15px;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.4px;
color: #757575;
}

.table-fields-mobile{
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 12px;
line-height: 20px;
/* identical to box height, or 125% */

display: flex;
align-items: center;
text-align: center;
letter-spacing: 0.4px;
color: #757575;
}

.title-text {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 40px;
line-height: 40px;
text-align: center;
letter-spacing: 0.02em;
color: #00A1B7;
}

.title-text-mobile {
font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 30px;
line-height: 40px;
text-align: center;
letter-spacing: 0.02em;
color: #00A1B7;
}

.grey-background{
  background-color: #F5F5F5;
}

.grey-outline {
  border: 1px solid #E0E0E0;
}

.title-subtext {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 22px;
text-align: center;
letter-spacing: 0.1px;
color: #212121;
}

.policy-txt {
  font-family: 'Roboto';
font-style: normal;
font-weight: 400;
font-size: 18px;
line-height: 140%;
/* or 18px */

letter-spacing: -0.04em;

/* Gray/600 */

color: #757575;
}

</style>
